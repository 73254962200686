<template>
  <v-data-table
    :headers="tableHeader"
    :items="monitoring"
    single-expand
    expanded.sync="expanded"
    item-key="title"
    show-expand
    class="elevation-1"
    :items-per-page="100"
    hide-default-footer
    :search="search"
    mobile-breakpoint="0"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title v-if="!isMobile">Состояние объектов</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          clearable
          append-icon="mdi-magnify"
          label="Поиск"
          single-line
          hide-details
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-text-field
          v-if="!isMobile"
          v-model="backupText"
          append-icon="mdi-backup-restore"
          label="Бэкапы сегодня"
          single-line
          hide-details
          disabled
        ></v-text-field>
        <v-spacer></v-spacer>
        <!-- <v-btn @click="rebootClients">reboot svc</v-btn> -->
        <!-- <v-btn @click="refresh">Обновить</v-btn> -->
      </v-toolbar>
    </template>
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        <v-card>
          <v-card-title v-if="!isMobile">Подробности</v-card-title>
          <v-card-text> Платформа: {{ item.OS }} </v-card-text>
          <v-card-text> socketId: {{ item.socketId }} </v-card-text>
          <v-card-text> последний коммит: {{ item.gitLatestCommit }} </v-card-text>
          <v-card-actions v-if="userAuth">
            <v-btn @click="FileExplorerEnable({ bpId: item.id, title: item.title })"> Обмен файлами </v-btn>
          </v-card-actions>
          <v-dialog width="1200" v-model="filesDialog">
            <FileExplorer />
          </v-dialog>
        </v-card>
        <v-data-table
          class="sub-table"
          :headers="linesHeaders"
          :items="item.lines"
          :items-per-page="200"
          hide-default-footer
        ></v-data-table>
      </td>
    </template>
  </v-data-table>
</template>


<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import FileExplorer from '../components/FileExplorer'
export default {
  data() {
    return {
      filesDialog: false,
      search: '',
      objectHeaders: [
        {
          text: 'Наименование',
          align: 'start',
          sortable: false,
          value: 'title',
        },
        { text: 'Состояние', value: 'state' },
        { text: 'id', value: 'id' },
        // { text: "socketId", value: "socketId" },
        // { text: "Последний коммит", value: "gitLatestCommit" },
        { text: 'Обновлено', value: 'timeCheck' },
        { text: 'RAM', value: 'RAM' },
        { text: 'HDD', value: 'HDD' },
        { text: 'CPU', value: 'CPU' },
        // { text: "monitoring", value: "monitoring" },
      ],
      linesHeaders: [
        {
          text: 'Наименование',
          align: 'start',
          sortable: false,
          value: 'stream',
        },
        { text: 'Состояние', value: 'state' },
        { text: 'Номер', value: 'num' },
        // { text: "monitoring", value: "monitoring" },
      ],
      objectHeadersMobile: [
        {
          text: 'Наименование',
          align: 'start',
          sortable: false,
          value: 'title',
        },
        { text: 'Состояние', value: 'state' },
      ],
    }
  },
  methods: {
    ...mapActions(['getMonotoringObjects', 'getOnlineObjects', 'rebootClients', 'getFiles']),
    ...mapMutations(['updateFilesObject', 'updateFolderPath']),
    FileExplorerEnable(data) {
      this.filesDialog = !this.filesDialog
      this.updateFilesObject(data)
      this.updateFolderPath('')
      this.getFiles()
    },
    async refresh() {
      await this.getMonotoringObjects()
    },
  },

  async created() {
    await this.getMonotoringObjects()
    // setInterval(async () => {
    //   await this.getMonotoringObjects();
    // }, 15 * 1000);
  },
  computed: {
    ...mapGetters(['monitoring', 'backupCounter', 'userAuth']),
    backupText() {
      return this.backupCounter !== null
        ? `Бекапы сегодня ${this.backupCounter} / ${this.monitoring.length}`
        : `Нет информации`
    },
    tableHeader() {
      return this.isMobile ? this.objectHeadersMobile : this.objectHeaders
    },
    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
  },
  components: {
    FileExplorer,
  },
}
</script>

<style lang="scss" scoped>
</style>